/**
* @Description 龙芯应用公社
* @author Loongson.Quzhenhong
* @date 21-7-6
*/
<template>
  <div class="contact">
    <div class="top">
      <div class="font">联系我们</div>
      <div class="content">
        <div class="left">
          <img :src="manImg" alt="">
        </div>
        <div class="right">
          <div class="application">请留下您对平台的宝贵意见</div>
          <el-form :model="form" :rules="formRules" ref="form">
            <!--:xs="10" :sm="10" :md="10"-->
            <div class="first">

              <el-form-item class="first_one" prop="username">
                <el-input v-model="form.username" placeholder="姓名"></el-input>
              </el-form-item>

              <el-form-item class="telph" prop="telephone">
                <el-input v-model="form.telephone" type='number' placeholder="手机号"></el-input>
              </el-form-item>

            </div>

            <el-form-item class="contact_sec" prop="email">
              <el-input v-model="form.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item class="contact_sec" prop="content">
              <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="留言..."
                      clearable
                      v-model="form.content"
                      resize="none"
              >
              </el-input>
            </el-form-item>
          </el-form>
          <div class="buttons">
            <el-button plain class="buttons_sub" type="primary" @click="submit">提交</el-button>
            <el-button plain class="buttons_sub" type="primary" @click="reset">重置</el-button>
          </div>
        </div>
      </div>
      <div class="dialogContent">
        <p>提交应用上架，请邮件联系{{getContactEmail()}}，提供以下信息：</p>
        <p>应用名称、开发者姓名、联系方式、公司名称、公司描述、应用logo、应用截图(最少3张)、版本号、应用简介、应用详细说明、应用程序包（附件）。</p>
<!--        <p>联系地址：山西省长治市中天驾校对面人才服务中心719室</p>-->
<!--        <p>感谢您对我们的信任，如果您想上架您的应用的话，请携带以下信息，通过以上方式联系我们。</p>-->
<!--        <p>应用名称、开发者姓名、联系方式、公司名称、公司描述、应用logo、应用截图(最少3张)、版本号、应用简介、应用详细说明、应用程序包（附件）</p>-->
      </div>

    </div>
    <div class="bottom">
      <div class="bottom_item">
        <img :src="locationImg" alt="">
        <div>
          长治(龙芯)信创产业园
        </div>
      </div>
      <div class="bottom_item">
        <img :src="telephoneImg" alt="">
        <div>
          010-62546668
        </div>
      </div>
      <div class="bottom_item">
        <img :src="emailImg" alt="">
        <div>
          {{getContactEmail()}}
        </div>
      </div>

    </div>
<!--    <el-dialog-->
<!--            title="应用开发提交流程"-->
<!--            :visible.sync="dialogVisible"-->
<!--            width="50%"-->
<!--    >-->

<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="dialogVisible = false">关闭</el-button>-->
<!--  </span>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
  import {leaveMessage} from '@/utils/network'
  import emailUtils from "@/utils/emailUtils";
  import conf from "@/utils/conf"

  export default {
    name: 'Contact',
    data() {
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
          //console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
      var checkEmail = (rule, value, callback) => {
        const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
        if (!value) {
          return callback(new Error('邮箱不能为空'))
        }
        setTimeout(() => {
          if (mailReg.test(value)) {
            callback()
          } else {
            callback(new Error('请输入正确的邮箱格式'))
          }
        }, 100)
      }
      return {
        dialogVisible: false,
        form: {
          username: '',
          content: '',
          email: '',
          telephone: ''
        },
        formRules: {
          telephone: [
            {validator: checkPhone, trigger: 'blur'}
          ],
          username: [{required: true, message: '请输入姓名', trigger: 'blur'},
            {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}],
          content: [
            {required: true, message: '请输入留言内容', trigger: 'blur'},
            {min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur'}
          ],
          //怀抱虽然不能逗留， 何不在的时候一边相守， 一边了、
          email: [
            {validator: checkEmail, trigger: 'blur'},
            {max: 100, message: '长度不能大于100个字符', trigger: 'blur'}
          ]

        },
        manImg:conf.getManImg(),
        locationImg:conf.getLocationImg(),
        telephoneImg:conf.getTelephoneImg(),
        emailImg:conf.getEmailImg()
      }
    },
    computed: {
      id() {
        return this.$store.state.id
      },
      token() {
        return this.$store.state.token
      }
    },
    methods: {
      async submit() {
        if (this.token) {
          this.$refs.form.validate(async flag => {
            if (flag) {
              const obj = this.form
              obj.user_id = this.id
              const {data: res} = await leaveMessage(obj)
              // console.log(res)
              if (res.code !== 200) return this.$message.error(`${res.message}`)
              this.$message.success('感谢您的建议')
              this.$refs.form.resetFields()
            } else {
              this.$message.error('请填写完整表单')
            }
          })
        } else {
          this.$store.dispatch('changeDialogShow', {
            loginDialogVisible: true
          })
        }


      },
      getContactEmail(){
        return emailUtils.getEmail()
      },
      reset() {
        this.$refs.form.resetFields()
      }
    },

    components: {}
  }
</script>

<style lang="less" scoped>
  .contact {
    .top {
      width: 80%;
      min-width: 1224px;
      margin: 18px auto;
      height: 530px;
      background-color: #fff;
      padding-top: 6px;

      .font {
        font-weight: 700;
        font-size: 18px;
        margin: 10px;
      }

      .content {
        min-width: 1224px;
        display: flex;
        margin-left: 10%;

        .left {
          margin-top: 0px;
        }

        .right {
          .application {
            margin-bottom: 20px;
            text-align: center;
            /*cursor: pointer;*/
            color: #000000;
            font-size: 22px;
            font-weight: 700;
          }

          margin-left: 140px;

          .first {
            //width: 480px;
            .telph {
              /deep/ input::-webkit-outer-spin-button,
              /deep/ input::-webkit-inner-spin-button {
                -webkit-appearance: none !important;
              }

              /deep/ input[type='number'] {
                -moz-appearance: textfield !important;
              }
            }

            .first_one {
              margin-right: 20px;
            }

            display: flex;
          }

          .contact_sec {
            margin-top: 12px;
            width: 600px;

            .el-input {
              .el-input__inner {
                width: 100%;
              }

            }
          }

          .buttons {
            margin-right: 80px;
            display: flex;
            margin-top: 140px;
            justify-content: flex-end;

            .buttons_sub {
              width: 150px;
              //height: 30px;
              text-align: center;
              border-radius: 180px;
            }
          }
        }
      }


    }

    .bottom {
      min-width: 1070px;
      background-color: #fff;
      width: 80%;
      margin: 18px auto;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .bottom_item {
        text-align: center;

        img {
          margin-bottom: 12px;
        }
      }
    }
    /*div{*/
    /*  overflow: hidden;*/
    /*  position: relative;*/
    /*  display: table-cell;*/
    /*  text-align: center ;*/
    /*  vertical-align: middle;*/
    /*}*/

    .dialogContent {
      p {
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #696969;
      }
    }

  }

</style>
